<template>
	<div class="shopping-cart">
		<div v-for="(item, index) in cartList" class="goods-item" v-if="cartList"
			:class="index===cartList.length-1?'red':''">
			<div class="title">
				<van-checkbox v-model="item.checked" @click="checkAlls(item,index)" />
				<!-- <van-checkbox v-model="checked">复选框</van-checkbox> -->
				<div class="nameN" @click="goShopDetail(item.sid)">{{item.name}}
					<van-icon name="arrow" />
				</div>
			</div>
			<!-- <van-checkbox-group v-model="result" ref="checkboxGroup"> -->
			<van-swipe-cell style="height: 100%;width: 100%;" v-for="(items, ins) in item.item">
				<div style="display: flex;width: 100%;position: relative;" class="zhezhao" >
					<div class="nmhh" v-if="items.status == 3">
						<p style="background: red;width: 60px;height: 60px;border-radius: 50%;text-align: center;line-height: 60px;">已失效</p>
					</div>
					<van-checkbox :name="items" :ref="el => checkboxRefs[ins] = el" @click.stop v-model="items.checked"
						@click="checked(items,ins,index)" />
					<van-card :price="items.price" :desc="items.attr" :title="items.name" :thumb="imageUrl + items.image"
						@click-thumb="goGoodsDetail(items.pid)">
						<template #footer>
							<van-stepper v-model="items.count" integer @plus="changeNum(items,1,ins)" :max="items.stock"
								@minus="changeNum(items,-1,ins)" disable-input />
						</template>
					</van-card>
				</div>
				<template #right>
					<van-button square text="删除" type="danger" class="delete-button" @click="deleteCart(items.id)" />
				</template>
			</van-swipe-cell>
			<!-- </van-checkbox-group> -->
			<div></div>

		</div>


		<van-empty description="暂无商品" v-else />
		<div v-if="cartList">
			<van-empty class="custom-image" :image="emptyImg" description="暂无商品" v-if="emptyStatus" />
			<van-submit-bar v-if="!emptyStatus" :price="price * 100" button-text="去结算" @submit="goOrderConfirm">
				<van-checkbox v-model="isCheckAll" @click="checkAll">全选</van-checkbox>
			</van-submit-bar>
		</div>

	</div>
</template>

<script>
	import {
		ref,
		onBeforeUpdate
	} from 'vue';
	import {
		changeGoodsNum,
		deleteCart
	} from '@/api/shop'
	import {
		cartLst,
		confirm
	} from '@/api/shopCart'
	import {
		getUserAddressList
	} from '../../api/user.js'

	import {
		orderSubmit
	} from '../../api/order.js'
	import {
		Toast,
		Dialog
	} from "vant";

	export default {
		setup() {
			const onSubmit = () => Toast('点击按钮');
			const onClickLink = () => Toast('修改地址');
			// const checked = ref(true);
			const checkboxRefs = ref([]);
			const value = ref(1);
			const toggle = (index) => {
				checkboxRefs.value[index].toggle();
			};

			return {
				onSubmit,
				onClickLink,
				toggle,
				// checked,
				checkboxRefs,
				value,

			};
		},
		inject: ['reload'],
		data() {
			return {
				cartList: [],
				imageUrl: this.url.imageUrl,
				list: ['a', 'b'],
				newDataArr: {
					sid: '',
					cart_id: ''
				},
				result: [],
				isCheckAll: false,
				isCheckAlls: '',
				defaultId: '',
				cartIdList: [],
				price: 0,
				emptyStatus: false,
				emptyImg: require('@/assets/zwsp.png'),
				listC: '',
				ArrList: [],
				// checked: true,
				listArr: []
			}
		},

		mounted() {
			// console.log(this.$route.query)
			this.$toast.loading({
				message: '加载中...',
				forbidClick: true,
			});
			Promise.all([this.cartLst(), this.getUserAddressList()]).then((res) => {
				// console.log(res)
				this.$toast.clear()

			}).catch((error) => {
				console.log(error)

			});
			// this.cartLst()
			// this.goList()
		},
		methods: {
			//删除购物车
			deleteCart(id) {
				deleteCart({
					id: id
				}).then((res) => {
					console.log(res)
					if (res.code == 200) {
						this.$toast('删除成功')
						this.reload()
					}
				})
			},
			goGoodsDetail(id) {
				this.$router.push({
					path: '/goodsDetail',
					query: {
						id: id
					}
				})
			},

			goShopDetail(id) {
				console.log(id)
				this.$router.push({
					path: '/shopDetail',
					query: {
						id: id
					}
				})
			},
			//修改商品数量
			changeNum(items, type, ins) {
				items.checked ? this.price += Number(items.price) * type : this.price
				let params = {}
				params.id = items.id
				params.count = items.count + type
				changeGoodsNum(params).then((res) => {
					console.log(res)
					if (res.code == 400) {
						items.count = items.count - 1
					} else {
						console.log(res)
					}
				})
			},
			//去结算
			goOrderConfirm() {
				let sid = 0;
				this.cartList.forEach((a, b) => {
					// console.log(a.sid)
					for (var i = 0; i < a.item.length; i++) {
						if (a.item[i].checked == true) {
							sid = a.sid
							this.cartIdList.push(a.item[i].id)
						}
					}
				})
				this.newDataArr.sid = sid
				this.newDataArr.cart_id = this.cartIdList
				// console.log(this.newDataArr)
				if (this.cartIdList.length > 0) {
					this.$router.push({
						path: '/orderConfirm',
						query: {
							orderDataList: this.cartIdList,
							sid: sid,
							address_id: this.defaultId
						}
					})
				} else {
					this.$toast('请选择商品')
				}
			},
			//提交订单
			onSubmit() {
				// console.log(12)
				let params = {
					carts: [],
					address_id: this.defaultId
				}
				let sid = 0;
				this.cartList.forEach((a, b) => {
					// console.log(a.sid)
					for (var i = 0; i < a.item.length; i++) {
						if (a.item[i].checked == true) {
							sid = a.sid
							this.cartIdList.push(a.item[i].id)
						}
					}
				})
				this.newDataArr.sid = sid
				this.newDataArr.cart_id = this.cartIdList
				// console.log(this.newDataArr)		
				params.cart_id.concat(this.newDataArr)
				orderSubmit(params).then((res) => {
					console.log(res)
				})
			},
			//获取地址列表
			async getUserAddressList() {
				await getUserAddressList().then((res) => {
					console.log(res)
					if (res.data) {
						res.data.forEach((v, i) => {
							if (v.isDefault) {
								this.defaultId = v.id
							}
						})
					}
				})
			},
			//选择商品
			checked(items, ins, index) {
				// console.log(ins, index)
				this.cartList.forEach((v, i) => {
					this.listC = this.cartList[i].item
					for (var i = 0; i < this.listC.length; i++) {
						this.ArrList.push(this.listC[i])
					}
				})
				let arr = [...this.ArrList]
				let arrs = [...this.cartList]
				// console.log(arrs)
				let statusArr = []
				let totalNum = 0
				var ids = true
				// console.log(arrs[index])
				for (var i = 0; i < arrs[index].item.length; i++) {
					// console.log(arrs[index].item[i].checked)
					if (arrs[index].item[i].checked != true) {
						ids = false
					}
				}
				arrs[index].checked = ids
				arr.forEach((v, i) => {
					statusArr.push(v.checked)
				})
				this.AddSum()
				var checkAllStatus = statusArr.every(function(value) {
					return value;
				});
				// console.log(checkAllStatus)
				checkAllStatus ? this.isCheckAll = true : this.isCheckAll = false
				// console.log(this.price)
			},
			checkAlls(item, index) {
				// console.log(item)
				let arr = [...this.cartList]
				let statusArr = []
				arr.forEach((v, i) => {
					statusArr.push(v.checked)
				})
				var checkAllStatuss = statusArr.every(function(value) {
					return value;
				});
				checkAllStatuss ? this.isCheckAll = true : this.isCheckAll = false
				// console.log(item)
				this.isCheckAlls = item.checked
				var checkStatuss = this.isCheckAlls
				item.item.forEach((v, i) => {
					v.checked = checkStatuss
				})
				this.AddSum()
			},
			AddSum() {
				let totalNum = 0
				for (var i = 0; i < this.cartList.length; i++) {
					for (var j = 0; j < this.cartList[i].item.length; j++) {
						var num = this.cartList[i].item[j]
						totalNum += num.checked ? num.count * num.price : 0
					}
				}
				this.price = totalNum
			},
			//全选
			checkAll() {
				var checkStatus = this.isCheckAll
				let totalNum = 0
				this.cartList.forEach((v, i) => {
					this.cartList[i].checked = checkStatus
					this.listC = this.cartList[i].item
					for (var i = 0; i < this.listC.length; i++) {
						this.ArrList.push(this.listC[i])
						this.listC[i].checked = checkStatus
					}
				})
				// this.price = totalNum
				this.AddSum()
			},
			toggleAll() {
				// this.$refs.checkboxGroup.toggleAll();
			},
			//获取购物车列表
			async cartLst() {
				await cartLst().then((res) => {
					console.log(res)
					this.cartList = res.data
				})
			},
		}
	};
</script>

<style scoped lang="less">
	.shopping-cart {
		padding-bottom: 50px;

		.red {
			margin-bottom: 40px;
		}

		.goods-item {
			display: flex;
			// align-items: center;
			justify-content: space-between;
			flex-direction: column;
			padding: 10px 15px;
			background-color: #FFFFFF;

			.title {
				display: flex;
				align-items: center;

				.nameN {
					display: flex;
					align-items: center;
					margin-left: 26px;
				}
			}

			.delete-button {
				height: 100% !important;

			}
			.nmhh{
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				right: 0;
				z-index: 99;
				background-color: rgba(0,0,0,0.3);
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.van-card {
				width: 90%;
				margin-left: 10px;
			}
		}

		.goods-item::v-deep(.van-swipe-cell__right) {
			height: 100% !important;
			top: 0 !important;
			display: flex;
			justify-content: center;
			align-items: center;

		}

		.van-submit-bar {
			bottom: 50px;
		}
	}
</style>
