import request from '@/utils/request'

// 购物车列表
export function cartLst() {
    return request({
        url: '/cart/lst',
        method: 'get'
    })
}
// 订单验证
export function confirm(data) {
    return request({
        url: '/order/confirm',
        method: 'post',
        data
    })
}